<!-- Navbar start -->
<section id="header" style="background: #1679ab !important">
  <div class="overlay"></div>
  <div class="container-fluid">
    <div class="container px-0">
      <nav
        class="navbar navbar-expand-xl"
        style="border-bottom: 2px solid white"
      >
        <a [routerLink]="['']" class="navbar-brand">
          <!-- <h1 class="text-white display-6">LT Academy HUB</h1> -->
          <img
            src="assets/lgbtq/logo.png"
            class="img-fluid rounded"
            style="width: auto; height: 120px"
          />
        </a>
        <button
          class="navbar-toggler py-2 px-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span class="fa fa-bars text-primary"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <div class="navbar-nav d-lg-none">
            <a [routerLink]="['']" class="nav-item nav-link">Home</a>

            <a href="https://lgbtqtalks.site/" class="nav-item nav-link"
              >LBGTQTalks</a
            >
            <a href="https://lgbtqtweets.site/" class="nav-item nav-link"
              >LBGTQTweet</a
            >
            <a href="https://umyoappt.site/" class="nav-item nav-link"
              >Booking</a
            >
            <a href="https://umyoevents.site/" class="nav-item nav-link"
              >Events</a
            >
            <a href="https://live.umyoconvo.site/" class="nav-item nav-link"
              >Podcast</a
            >

            <a href="https://uchatyes.com/" class="nav-item nav-link">ChaGPT</a>
            
              <a href="https://UPMRadio.site/" class="nav-link nav-item"
                >Radio</a
              >
            
            <a [routerLink]="['/cf']" class="nav-item nav-link"
              >Card Features</a
            >
            <a [routerLink]="['/package']" class="nav-item nav-link"
              >View Services</a
            >
            <a [routerLink]="['/ai']" class="nav-item nav-link"
              >Additional Features</a
            >
          </div>
          <div class="d-flex m-3 ms-auto">
            <p class="text-white" style="margin: 2px 25px; font-size: 25px">
              UMYO
            </p>
            <button
              class="btn-search btn border border-white btn-md-square rounded-circle me-4"
              data-bs-toggle="modal"
              data-bs-target="#searchModal"
            >
              <i class="fas fa-search text-white"></i>
            </button>
            <a href="#" class="position-relative me-4 my-auto">
              <i class="fa fa-shopping-cart text-white fa-2x"></i>
            </a>
            <a href="#" class="my-auto">
              <button class="btn btn-primary bg-white text-dark border-0 p-2">
                Get Started
              </button>
            </a>
          </div>
        </div>
      </nav>
      <nav class="navbar navbar-expand-lg d-lg-block d-none menu-nav2">
        <div class="container-fluid p-0">
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mb-2 mb-lg-0">
              <li class="nav-item">
                <a [routerLink]="['']" class="nav-link">Home</a>
              </li>

              <li class="nav-item">
                <a href="https://lgbtqtalks.site/" class="nav-link"
                  >LBGTQTalks</a
                >
              </li>
              <li class="nav-item">
                <a href="https://lgbtqtweets.site/" class="nav-link"
                  >LBGTQTweet</a
                >
              </li>
              <li class="nav-item">
                <a href="https://umyoappt.site/" class="nav-link">Booking</a>
              </li>
              <li class="nav-item">
                <a href="https://umyoevents.site/" class="nav-link">Events</a>
              </li>
              <li class="nav-item">
                <a href="https://live.umyoconvo.site/" class="nav-link"
                  >Podcast</a
                >
              </li>
              <li class="nav-item">
                <a href="https://uchatyes.com/" class="nav-link">ChaGPT</a>
              </li>

              <li class="nav-item">
                <a href="https://UPMRadio.site/" class="nav-link"
                  >Radio</a
                >
              </li>

              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/cf']">Card Features</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/package']"
                  >View Services</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/ai']"
                  >Additional Features</a
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <!-- Navbar End -->

  <!-- Modal Search Start -->
  <div
    class="modal fade"
    id="searchModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content rounded-0">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Search by keyword</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body d-flex align-items-center">
          <div class="input-group w-75 mx-auto d-flex">
            <input
              type="search"
              class="form-control p-3"
              placeholder="keywords"
              aria-describedby="search-icon-1"
            />
            <span id="search-icon-1" class="input-group-text p-3"
              ><i class="fa fa-search"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Search End -->

  <!-- Hero Start -->
</section>

<section id="page-top" class="lft-hero">
  <div class="lft-video">
    <!-- <video loop="" muted="" playsinline="" autoplay="" poster="https://cdn.shopify.com/s/files/1/0413/5891/7796/files/lft-hero.jpg?v=1678132516">
  
  <source src="https://cdn.shopify.com/videos/c/o/v/5bc68d777dab433ebc9f9f62d35dcd80.mp4" type="video/mp4">
  </video> -->
    <video
      autoplay
      loop
      [src]="videoUrl"
      style="width: 100%; height: 100%"
      [muted]="true"
    ></video>

    <div class="overlay"></div>
  </div>

  <div class="container py-5">
    <div class="row g-5 align-items-center">
      <div class="col-md-12 col-lg-7">
        <div class="page-width">
          <div class="lft-hero__content">
            <h1 class="mb-3">UMYO LGBTQ Hub</h1>
            <p class="mb-5">
              Introducing UMYO LGBTQ Hub, a dynamic platform tailored for
              authors, publishers, and literary enthusiasts within the lesbian
              and gay community. Seamlessly upload your latest manuscripts,
              author interviews, literary events, industry insights, and
              publishing milestones to engage with a vast audience of fellow
              LGBTQ writers and book enthusiasts.
            </p>
            <div class="moretext-he" style="display: none">
              <p class="mb-5">
                UMYO LGBTQ Hub serves as the ultimate nexus for LGBTQ writers
                across various genres, fostering connections, collaborations,
                and mutual support within the literary community. Authors and
                publishers can now register their profiles and link up with
                like-minded individuals using personalized ID codes,
                streamlining networking and collaboration opportunities within
                the LGBTQ community.
              </p>
              <p class="mb-5">
                Join UMYO LGBTQ Hub today and immerse yourself in a vibrant
                ecosystem dedicated to nurturing creativity, fostering
                professional growth, and celebrating the diverse voices that
                enrich our LGBTQ literary landscape.
              </p>
            </div>
            <a
              class="moreless-button-he text-white btn btn-dark"
              style="text-decoration: none"
              href="#he"
              >Read more</a
            >
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-5 px-5">
        <div class="grid__item medium-up--one-half">
          <div class="videos__wrapper">
            <div class="videos__wrapper--video">
              <iframe
                width="560"
                height="315"
                src="https://umyovideo.com/embed/2o8UCd5PfgIyv68?color=04abf2"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="page-width">
  <div class="lft-hero__content">
  <h1>CREATE IMPRESSIVE MILITARY CARDS</h1>
      <p> Academy Connect introduces an innovative networking solution exclusively for military personnel.</p>
  
  
  <a class="button js-no-transition" href="#linq-form" onclick="track('Clicked LFT hero cta')">Read More</a>
  </div>
  </div> -->
</section>

<section id="logo-slider" style="background: #1679ab !important">
  <div class="container-fluid mt-5">
    <div class="container-fluid mt-1 mobile" style="overflow-x: hidden">
      <h1 class="text-center mb-5 text-white">
        THE UMYO All in one Networking App
      </h1>
      <ngx-slick-carousel
        #slickModal1="slick-carousel"
        [config]="slideConfig1"
        class="carousel"
      >
        <div ngxSlickItem *ngFor="let slide of slides1" class="slide">
          <div class="mb-5"><img [src]="slide.img" /></div>
        </div>
      </ngx-slick-carousel>

      <ngx-slick-carousel
        #slickModal2="slick-carousel"
        [config]="slideConfig2"
        class="carousel"
        [dir]="'rtl'"
      >
        <div ngxSlickItem *ngFor="let slide of slides2" class="slide">
          <div class="mb-5"><img [src]="slide.img" /></div>
        </div>
      </ngx-slick-carousel>
    </div>
    <div class="container-fluid mt-1 mobile2" style="overflow-x: hidden">
      <h1 class="text-center mb-5 text-white">
        THE "UMYO" All in one Networking App
      </h1>
      <ngx-slick-carousel
        #slickModal1="slick-carousel"
        [config]="slideConfig5"
        class="carousel"
      >
        <div ngxSlickItem *ngFor="let slide of slides1" class="slide">
          <div class="mb-5"><img [src]="slide.img" /></div>
        </div>
      </ngx-slick-carousel>

      <ngx-slick-carousel
        #slickModal2="slick-carousel"
        [config]="slideConfig6"
        class="carousel"
        [dir]="'rtl'"
      >
        <div ngxSlickItem *ngFor="let slide of slides2" class="slide">
          <div class="mb-5"><img [src]="slide.img" /></div>
        </div>
      </ngx-slick-carousel>
    </div>
    <div class="container-fluid mt-1 desktop" style="overflow-x: hidden">
      <h1 class="text-center mb-5 text-white">
        THE "UMYO" All in one Networking App
      </h1>
      <ngx-slick-carousel
        #slickModal1="slick-carousel"
        [config]="slideConfig3"
        class="carousel"
      >
        <div ngxSlickItem *ngFor="let slide of slides1" class="slide">
          <div class="mb-5"><img [src]="slide.img" /></div>
        </div>
      </ngx-slick-carousel>

      <ngx-slick-carousel
        #slickModal2="slick-carousel"
        [config]="slideConfig4"
        class="carousel"
        [dir]="'rtl'"
      >
        <div ngxSlickItem *ngFor="let slide of slides2" class="slide">
          <div class="mb-5"><img [src]="slide.img" /></div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</section>

<!-- Hero End -->
<!------- tab section start-------->
<section id="header" style="background: #1679ab !important">
  <div class="container-fluid srice py-5">
    <div class="container py-5">
      <h1
        class="text-white text-center"
        style="font-weight: 600; margin-bottom: 60px"
      >
        Search For UMYO LGBTQ Hub of All Types
      </h1>
      <div class="row">
        <div class="col-lg-5">
          <h4 class="mb-4 text-white">Search For The UMYO LGBTQ Hub</h4>
          <form [formGroup]="form2" class="text-center">
            <div class="row">
              <div class="col-lg-6 mb-4">
                <input
                  type="name"
                  formControlName="selectedName"
                  class="form-control"
                  placeholder="Name"
                />
              </div>

              <div class="col-lg-6 mb-4">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  formControlName="selectedBusiness"
                >
                  <option selected value="" disabled>BUSINESS TYPE</option>
                  <option *ngFor="let item of businessType" [value]="item">
                    {{ item }}
                  </option>
                </select>
              </div>

              <div class="col-lg-6 mb-4">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  formControlName="selectedLocation"
                >
                  <option selected value="" disabled>Location</option>
                  <option *ngFor="let item of location" [value]="item">
                    {{ item }}
                  </option>
                </select>
              </div>

              <div class="col-lg-6 mb-4">
                <input
                  type="text"
                  formControlName="selectedCity"
                  class="form-control"
                  placeholder="City"
                />
              </div>

              <div class="col-lg-6">
                <h4 class="mb-4 text-white text-center">
                  I PLEDGE TO REPORT VIOLENT CRIMES
                </h4>
              </div>

              <div class="col-lg-6 mb-4">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  formControlName="selectedRace"
                >
                  <option selected value="" disabled>Race</option>
                  <option *ngFor="let item of race" [value]="item">
                    {{ item }}
                  </option>
                </select>
              </div>

              <div class="col-lg-6 mb-4">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  formControlName="selectedGender"
                >
                  <option selected value="" disabled>Gender</option>
                  <option *ngFor="let item of gender" [value]="item">
                    {{ item }}
                  </option>
                </select>
              </div>
              <div class="col-lg-6 mb-4">
                <button
                  type="submit"
                  class="btn btn-info text-white py-2 px-5 w-100"
                  (click)="searchCards()"
                  [disabled]="!form2.valid"
                >
                  Search
                </button>
              </div>
            </div>
          </form>

          <div class="bg-info rounded text-white py-2 px-3 my-5">
            <p class="text-white mb-4">Cards:</p>
            <p
              class="text-white mb-4"
              style="cursor: pointer"
              [routerLink]="'/cards/share-card/' + card.id"
              *ngFor="let card of cardsSearched"
            >
              {{ card.cardTitle }}
            </p>
          </div>
        </div>
        <div class="col-lg-7 mt-5">
          <div class="content">
            <!-- Nav pills -->
            <ul class="nav nav-pills" role="tablist">
              <li class="nav-item" style="cursor: pointer">
                <a
                  class="nav-link"
                  (click)="checkRoute()"
                  [class.active]="showLoginTab"
                  data-toggle="pill"
                  >Login</a
                >
              </li>
              <li class="nav-item" style="cursor: pointer">
                <a
                  class="nav-link"
                  (click)="checkRoute()"
                  [class.active]="!showLoginTab"
                  data-toggle="pill"
                  >Register</a
                >
              </li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content rounded p-4">
              <div
                id="login"
                class="container tab-pane"
                [class.active]="showLoginTab"
              >
                <form [formGroup]="form">
                  <div class="row">
                    <h1 class="text-center">UMYO For UMYO LGBTQ</h1>
                    <p class="text-center">
                      Register to Connect with UMYO LGBTQ
                    </p>

                    <br /><br /><br /><br /><br /><br />
                    <h2 class="text-info mb-3">Login</h2>
                    <div class="col-md-12 mb-5">
                      <div class="form-group">
                        <label style="font-size: 20px; font-weight: 600"
                          >Email
                          <sup style="color: red; font-size: 20px">*</sup>
                        </label>
                        <input
                          type="email"
                          id="email"
                          class="form-control"
                          formControlName="email"
                          required
                        />
                        <div
                          *ngIf="
                            form.get('email')?.hasError('required') &&
                            form.get('email')?.touched
                          "
                          class="text-danger"
                        >
                          Email is required.
                        </div>
                        <div
                          *ngIf="
                            form.get('email')?.hasError('email') &&
                            form.get('email')?.touched
                          "
                          class="text-danger"
                        >
                          Invalid email format.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mb-5">
                      <div class="form-group">
                        <label style="font-size: 20px; font-weight: 600"
                          >Password<sup style="color: red; font-size: 20px"
                            >*</sup
                          >
                        </label>
                        <input
                          type="password"
                          id="password"
                          class="form-control"
                          formControlName="password"
                          required
                        />
                        <div
                          *ngIf="
                            form.get('password')?.hasError('required') &&
                            form.get('password')?.touched
                          "
                          class="text-danger"
                        >
                          Password is required.
                        </div>
                      </div>
                      <div class="form-group" style="padding-top: 10px">
                        <label
                          style="
                            font-size: 16px;
                            font-weight: 400;
                            text-decoration: underline;
                            cursor: pointer;
                          "
                          [routerLink]="['/forgot-password']"
                          >Forgot Password?
                        </label>
                      </div>
                    </div>
                    <div class="col-md-12 mb-3">
                      <div class="form-group">
                        <div
                          class="custom-control custom-checkbox custom-checkbox-lg"
                        >
                          <input
                            style="width: 1rem; height: 1rem"
                            type="checkbox"
                            class="custom-control-input"
                            id="ageCheckbox"
                          />
                          <label
                            class="custom-control-label"
                            for="ageCheckbox"
                            style="
                              font-size: 20px;
                              font-weight: 600;
                              margin-left: 10px;
                            "
                          >
                            Are you above 13 years old?
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    (click)="openAgeModal('login')"
                    type="submit"
                    class="btn btn-info text-white mt-3 py-3 px-5"
                    style="margin: auto; display: block"
                    [disabled]="!form.valid"
                  >
                    Submit
                  </button>
                  <!-- <div class="bg-img">
    <img src="assets/img/illustrations-office.png"></div> -->
                </form>
              </div>

              <div
                id="regis"
                class="container tab-pane"
                [class.active]="!showLoginTab"
              >
                <form [formGroup]="form3">
                  <div class="row justify-content-center">
                    <h1 class="text-center">UMYO Pro</h1>
                    <div class="col-md-3 my-5">
                      <p class="text-center">starting at</p>
                      <h1 class="text-center">$5/mo</h1>
                      <p class="text-center">per agent</p>
                    </div>
                    <div class="col-md-1 my-5 d-none d-lg-block">
                      <div class="verticle"></div>
                    </div>
                    <div class="col-md-6 my-5">
                      <p>Includes:</p>
                      <li>40% off all UMYO products</li>
                      <li>3 free property listings per agent</li>
                      <li>Free standard CRM integrations</li>
                    </div>
                    <h2 class="mb-3 text-info">Register</h2>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-3">
                      <div class="form-group">
                        <label style="font-size: 20px; font-weight: 600"
                          >Frist Name
                          <sup style="color: red; font-size: 20px"
                            >*</sup
                          ></label
                        >
                        <input
                          type="text"
                          formControlName="registerFirstName"
                          class="form-control"
                          required
                          [ngClass]="{
                            'invalid-input':
                              !form3.get('registerFirstName')?.touched &&
                              submitButtonClicked &&
                              !form3.valid
                          }"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <div class="form-group">
                        <label style="font-size: 20px; font-weight: 600"
                          >Last Name
                          <sup style="color: red; font-size: 20px">*</sup>
                        </label>
                        <input
                          type="text"
                          formControlName="registerLastName"
                          class="form-control"
                          required
                          [ngClass]="{
                            'invalid-input':
                              !form3.get('registerLastName')?.touched &&
                              submitButtonClicked &&
                              !form3.valid
                          }"
                        />
                      </div>
                    </div>
                    <div class="col-md-12 mb-3">
                      <div class="form-group">
                        <label style="font-size: 20px; font-weight: 600"
                          >Phone Number
                          <sup style="color: red; font-size: 20px"
                            >*</sup
                          ></label
                        >
                        <input
                          type="text"
                          formControlName="registerPhone"
                          class="form-control"
                          required
                          [ngClass]="{
                            'invalid-input':
                              !form3.get('registerPhone')?.touched &&
                              submitButtonClicked &&
                              !form3.valid
                          }"
                        />
                      </div>
                    </div>

                    <div class="col-md-6 mb-3">
                      <div class="form-group">
                        <label style="font-size: 20px; font-weight: 600"
                          >Email
                          <sup style="color: red; font-size: 20px"
                            >*</sup
                          ></label
                        >
                        <input
                          type="email"
                          formControlName="registerEmail"
                          class="form-control"
                          required
                          [ngClass]="{
                            'invalid-input':
                              (!form3.get('registerEmail')?.touched ||
                                !form3.get('registerEmail')?.valid) &&
                              submitButtonClicked &&
                              (!form3.valid || form3.hasError('emailMismatch'))
                          }"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <div class="form-group">
                        <label style="font-size: 20px; font-weight: 600"
                          >Confirm Email
                          <sup style="color: red; font-size: 20px"
                            >*</sup
                          ></label
                        >
                        <input
                          type="email"
                          formControlName="registerConfirmEmail"
                          class="form-control"
                          required
                          [ngClass]="{
                            'invalid-input':
                              (!form3.get('registerConfirmEmail')?.touched ||
                                !form3.get('registerConfirmEmail')?.valid) &&
                              submitButtonClicked &&
                              (!form3.valid || form3.hasError('emailMismatch'))
                          }"
                        />
                      </div>
                    </div>
                    <div class="col-md-12 mb-3">
                      <div class="form-group">
                        <label style="font-size: 20px; font-weight: 600"
                          >Password<sup style="color: red; font-size: 20px"
                            >* </sup
                          ><sup style="color: red; font-size: 14px"
                            >( Length Should be greater than 6 )</sup
                          >
                        </label>
                        <input
                          type="password"
                          class="form-control"
                          required
                          formControlName="registerPassword"
                          [ngClass]="{
                            'invalid-input':
                              (!form3.get('registerPassword')?.touched ||
                                !form3.get('registerPassword')?.valid) &&
                              submitButtonClicked &&
                              !form3.valid
                          }"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6 mb-4">
                      <label style="font-size: 20px; font-weight: 600"
                        >BUSINESS TYPE<sup style="color: red; font-size: 20px"
                          >*</sup
                        >
                      </label>
                      <select
                        [ngClass]="{
                          'invalid-input':
                            (!form3.get('registerBusiness')?.touched ||
                              !form3.get('registerBusiness')?.valid) &&
                            submitButtonClicked &&
                            !form3.valid
                        }"
                        required
                        formControlName="registerBusiness"
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option value="" disabled selected>
                          Please Select
                        </option>
                        <option
                          *ngFor="let item of businessType"
                          [value]="item"
                        >
                          {{ item }}
                        </option>
                      </select>
                    </div>

                    <div class="col-lg-6 mb-4">
                      <div class="form-group">
                        <label style="font-size: 20px; font-weight: 600"
                          >City
                          <sup style="color: red; font-size: 20px"
                            >*</sup
                          ></label
                        >
                        <input
                          type="text"
                          formControlName="registerCityType"
                          class="form-control"
                          required
                          [ngClass]="{
                            'invalid-input':
                              !form3.get('registerCityType')?.touched &&
                              submitButtonClicked &&
                              !form3.valid
                          }"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6 mb-4">
                      <label style="font-size: 20px; font-weight: 600"
                        >Race<sup style="color: red; font-size: 20px">*</sup>
                      </label>
                      <select
                        [ngClass]="{
                          'invalid-input':
                            (!form3.get('registerRaceType')?.touched ||
                              !form3.get('registerRaceType')?.valid) &&
                            submitButtonClicked &&
                            !form3.valid
                        }"
                        required
                        formControlName="registerRaceType"
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option value="" disabled selected>
                          Please Select
                        </option>

                        <option *ngFor="let item of race" [value]="item">
                          {{ item }}
                        </option>
                      </select>
                    </div>

                    <div class="col-lg-6 mb-4">
                      <label style="font-size: 20px; font-weight: 600"
                        >Gender<sup style="color: red; font-size: 20px">*</sup>
                      </label>
                      <select
                        formControlName="registerGenderType"
                        class="form-select"
                        aria-label="Default select example"
                        [ngClass]="{
                          'invalid-input':
                            (!form3.get('registerGenderType')?.touched ||
                              !form3.get('registerGenderType')?.valid) &&
                            submitButtonClicked &&
                            !form3.valid
                        }"
                      >
                        <option value="" disabled selected>
                          Please Select
                        </option>
                        <option *ngFor="let item of gender" [value]="item">
                          {{ item }}
                        </option>
                      </select>
                    </div>

                    <div class="col-lg-6 mb-4">
                      <label style="font-size: 20px; font-weight: 600"
                        >Location<sup style="color: red; font-size: 20px"
                          >*</sup
                        >
                      </label>
                      <select
                        [ngClass]="{
                          'invalid-input':
                            (!form3.get('registerLocation')?.touched ||
                              !form3.get('registerLocation')?.valid) &&
                            submitButtonClicked &&
                            !form3.valid
                        }"
                        required
                        formControlName="registerLocation"
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option value="" disabled selected>
                          Please Select
                        </option>
                        <option *ngFor="let item of location" [value]="item">
                          {{ item }}
                        </option>
                      </select>
                    </div>

                    <div class="col-md-12 mb-3">
                      <div class="form-group">
                        <label style="font-size: 20px; font-weight: 600"
                          >Enter Referral Code</label
                        >
                        <input
                          type="text"
                          formControlName="registerReferralCode"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-md-12 mb-3">
                      <div class="form-group">
                        <div
                          class="custom-control custom-checkbox custom-checkbox-lg"
                        >
                          <input
                            style="width: 1rem; height: 1rem"
                            type="checkbox"
                            class="custom-control-input"
                            id="ageCheckbox"
                          />
                          <label
                            class="custom-control-label"
                            for="ageCheckbox"
                            style="
                              font-size: 20px;
                              font-weight: 600;
                              margin-left: 10px;
                            "
                          >
                            Are you above 13 years old?
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div (click)="submitForm()">
                    <button
                      (click)="openAgeModal('signup')"
                      [disabled]="!form3.valid"
                      class="btn btn-info text-white mt-3 py-3 px-5"
                      style="margin: auto; display: block"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h1 class="text-white my-3 featurs">UMYO LGBTQ Hub</h1>
      <p class="text-white my-3">
        Share your Business Portfolio, Community Achievements, Local Insights,
        Interviews, Experiences, Images.
      </p>
      <div class="moretext" style="display: none">
        <p class="text-white my-3">
          Community Highlights for a wide audience to explore. This all-in-one
          platform is essential for LGBTQ Writers, UMYO Businesses, and
          Community Connect professionals seeking greater visibility using
          virtual interactive concepts to network with others just like you. A
          safe and positive place to thrive in the LGBTQ community, Businesses,
          and organizations Connect community.
        </p>
      </div>
      <a
        class="moreless-button btn btn-light"
        style="text-decoration: none"
        (click)="toggleMoreText2()"
        >Read more</a
      >
    </div>
  </div>
</section>

<!-- packages modal  -->

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ show: showPackageModal, 'd-block': showPackageModal }"
  style="background-color: rgb(0, 0, 0, 0.8)"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Select One Package
        </h5>
        <button type="button" class="close" (click)="closePackageModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="max-height: 70vh; overflow-y: scroll">
        <div class="row">
          <div class="col-md-6" *ngFor="let package of packages; let i = index">
            <div class="outerCard" style="min-height: 30vh; cursor: pointer">
              <div
                class="card"
                [ngClass]="{ cardSelect: selectedPackage === package }"
                (click)="selectPackage(package)"
              >
                <div class="card-body">
                  <h5 class="card-title" style="color: rgb(35, 149, 243)">
                    $
                    {{ package.net_price ? package.net_price : package.price }}
                    <sup
                      style="
                        text-decoration: line-through;
                        font-size: 20px;
                        color: gray;
                        padding-left: 15px;
                      "
                      *ngIf="package.net_price"
                      >{{ package.price }}</sup
                    >
                  </h5>
                  <p class="card-text">Cards Limit: {{ package.limit }}</p>
                  <p class="card-duration">Duration: {{ package.expire_in }}</p>

                  <div
                    class="description-container"
                    style="max-height: 100px; overflow-y: auto"
                  >
                    <p class="card-description">{{ package.description }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-100" *ngIf="(i + 1) % 2 === 0"></div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button
          (click)="closePackageModal()"
          type="button"
          class="btn btn-secondary"
        >
          Close
        </button>

        <div
          *ngIf="selectedPackage?.price != '0'"
          [ngClass]="{
            hidden: selectedPackage == null || selectedPackage?.price == '0',
            paymentButtons: selectedPackage != null
          }"
        >
          <!-- <div id="paypal-button-container"></div> -->
          <!-- hide stripe -->

          <!-- <button
            type="button"
            style="background-color: rgb(35, 149, 243); color: white"
            class="btn btn-info"
            (click)="openStripeModal()"
          >
            Stripe
          </button> -->
          <button
            type="button"
            style="background-color: rgb(35, 149, 243); color: white"
            class="btn btn-info"
            (click)="paypalClick()"
          >
            paypal
          </button>
        </div>
        <div *ngIf="selectedPackage?.price == '0'">
          <button
            type="button"
            style="background-color: rgb(35, 149, 243); color: white"
            class="btn btn-info"
            (click)="continuePaymentButton()"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ show: showLoadingModal, 'd-block': showLoadingModal }"
  style="background-color: rgb(0, 0, 0, 0.8)"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container">
          <h3>{{ paypalTitleMessage }}</h3>
          <p style="padding-top: 15px">{{ paypalMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- stripe modal  -->

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ show: showStripeModal, 'd-block': showStripeModal }"
  style="background-color: rgb(0, 0, 0, 0.8)"
>
  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Pay Through Stripe
        </h5>
        <button type="button" class="close" (click)="closeStripeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <form class="checkout-form" [formGroup]="paymentForm">
            <h3>
              Amount
              {{
                selectedPackage?.net_price
                  ? selectedPackage?.net_price
                  : selectedPackage?.price
              }}
            </h3>
            <div class="row">
              <div class="col-md-12" style="padding-top: 17px">
                <div class="form-group">
                  <label
                    for="card-number"
                    style="
                      font-size: 17px;
                      font-weight: 600;
                      padding-bottom: 8px;
                    "
                    >Card Number 16 Digit (Without Dashes)</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="card-number"
                    data-stripe-element="cardNumber"
                    formControlName="cardNumber"
                    placeholder="XXXXXXXXXXXXXXXX"
                  />
                </div>
              </div>
              <div class="col-md-12" style="padding-top: 17px">
                <div class="form-group">
                  <label
                    for="card-expiry"
                    style="
                      font-size: 17px;
                      font-weight: 600;
                      padding-bottom: 8px;
                    "
                    >Expiration Date in (MM/YY) Format including /
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="card-expiry"
                    data-stripe-element="expiryDate"
                    formControlName="expiryDate"
                    placeholder="MM/YY"
                  />
                </div>
              </div>
              <div class="col-md-12" style="padding-top: 17px">
                <div class="form-group">
                  <label
                    for="card-cvc"
                    style="
                      font-size: 17px;
                      font-weight: 600;
                      padding-bottom: 8px;
                    "
                    >CVC 3 Digit</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="card-cvc"
                    data-stripe-element="cvc"
                    formControlName="cvc"
                    placeholder="XXX"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div
                class="col-md-12"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding-top: 10px;
                "
              >
                <button
                  type="submit"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  id="pay-button"
                  (click)="pay()"
                >
                  Pay Now
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeStripeModal()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ show: showAgeModal, 'd-block': showAgeModal }"
  style="background-color: rgb(0, 0, 0, 0.8)"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container">
          <h3>Are You 18+ of your age?</h3>
        </div>
        <p style="padding: 8px">
          You must be at least 13 years old to use the Service. However,
          children of all ages may use the Service and PLTalk Kids (where
          available) if enabled by a parent or legal guardian. Permission by
          Parent or Guardian If you are under 18, you represent that you have
          your parent or guardian’s permission to use the Service. Please have
          them read this Agreement with you. If you are a parent or legal
          guardian of a user under the age of 18, by allowing your child to use
          the Service, you are subject to the terms of this Agreement and
          responsible for your child’s activity on the Service. You can find
          tools and resources to help you manage your family’s experience on
          PLTalk in our Help Center
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="confirmAge()">
          Yes
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeAgeModal()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ show: showLoadingModal, 'd-block': showLoadingModal }"
  style="background-color: rgb(0, 0, 0, 0.8)"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container">
          <h3>Confirming Payment...</h3>
        </div>
      </div>
    </div>
  </div>
</div>

<!------- tab section end ---------->

<!-- Featurs Section End -->
<div
  class="container-fluid bg-dark text-white-50 footer"
  style="background: #1679ab !important; height: 100% !important"
>
  <div class="container py-5">
    <div class="row g-4">
      <div class="col-lg-2 col-md-6">
        <h4 class="text-light"><b>Contact us</b></h4>
        <div class="d-flex flex-column text-start footer-item">
          <a class="btn-link" (click)="openPolicyModal()">Privacy Policy</a>
          <a class="btn-link" (click)="openRefundModal()">Refund Policy</a>
          <a class="btn-link" (click)="openTermsModal()">Terms Of Service</a>
          <a class="btn-link" href="">Terms of Sales</a>
          <a class="btn-link" href="">UMYO Profile</a>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="d-flex align-items-center">
          <h4 class="text-light"><b>UMYO Community Categories</b></h4>
          <a class="moreless-button13" href="#text13"
            ><a class="moreless-button13" href="#text13"
              ><span class="material-symbols-sharp ms-3">chevron_right</span></a
            ></a
          >
        </div>
        <div class="moretext13" style="display: none">
          <div class="d-flex flex-column text-start footer-item">
            <a class="btn-link" href="https://umyobizdfw.com" target="_blank"
              >DFW connects</a
            >
            <a class="btn-link" href="https://umyomilitary.site" target="_blank"
              >Military Connects</a
            >
            <a class="btn-link" href="https://umyotruckers.site" target="_blank"
              >Truckers Connects</a
            >
            <a class="btn-link" href="https://umyoauto.site" target="_blank"
              >Automotive Connects</a
            >
            <a
              class="btn-link"
              href="https://umyohighschool.site"
              target="_blank"
              >Students Connects</a
            >
            <a
              class="btn-link"
              href="https://umyosportscards.com"
              target="_blank"
              >Sports Athletes Connects</a
            >
            <a class="btn-link" href="https://umyorealtor.site" target="_blank"
              >Realtors Connects</a
            >
            <a
              class="btn-link"
              href="https://umyorestaurant.com"
              target="_blank"
              >Restaurants Connects</a
            >
            <a class="btn-link" href="https://umyostylist.com" target="_blank"
              >Hair & Beauty Connects</a
            >
            <a class="btn-link" href="https://umyonegocio.site" target="_blank"
              >Hispanics Connects</a
            >
            <a
              class="btn-link"
              href="https://nubianbizcards.com"
              target="_blank"
              >Nubians Connects</a
            >
            <a class="btn-link" href="https://umyolawyer.site" target="_blank"
              >Attorneys Connects</a
            >
            <a
              class="btn-link"
              href="https://umyoreligions.site"
              target="_blank"
              >Religions Connects</a
            >
            <a
              class="btn-link"
              href="https://umyoentertainment.site"
              target="_blank"
              >Entertainers Connects</a
            >
            <a
              class="btn-link"
              href="https://umyonightlife.site"
              target="_blank"
              >Night Life Connects</a
            >
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="d-flex align-items-center">
          <h4 class="text-light"><b>UMYO Platforms</b></h4>
          <a class="moreless-button14" href="#text14"
            ><span class="material-symbols-sharp ms-3">chevron_right</span></a
          >
        </div>
        <div class="moretext14" style="display: none">
          <div class="d-flex flex-column text-start footer-item">
            <a
              class="btn-link"
              href="https://live.umyoconvo.site"
              target="_blank"
              >UMYO Conferences</a
            >
            <a class="btn-link" href="https://umyomusic.com" target="_blank"
              >UMYO Music</a
            >
            <a class="btn-link" href="https://umyopopup.site" target="_blank"
              >UMYO Market</a
            >
            <a class="btn-link" href="https://umyohost.com" target="_blank"
              >UMYO Hosting</a
            >
            <a class="btn-link" href="https://uchatyes.com" target="_blank"
              >UMYO Chatgpt</a
            >
            <a
              class="btn-link"
              href="https://umyotweets.com/guest"
              target="_blank"
              >UMYO Tweets</a
            >
            <a class="btn-link" href="https://cpswipe.com" target="_blank"
              >UMYO Date</a
            >
            <a
              class="btn-link"
              href="https://lieutenantsacademydfw.org/"
              target="_blank"
              >UMYO Tik Tok</a
            >
            <a
              class="btn-link"
              href="https://umyonetwork.com/network"
              target="_blank"
              >UMYO Connects</a
            >
            <a
              class="btn-link"
              href="https://lieutenantsacademydfw.org/"
              target="_blank"
              >UMYO Mail</a
            >
            <a
              class="btn-link"
              href="https://lieutenantsacademydfw.org/"
              target="_blank"
              >UMYO WhatsApp</a
            >
            <a
              class="btn-link"
              href="https://lieutenantsacademydfw.org/"
              target="_blank"
              >UMYO Games</a
            >
            <a class="btn-link" href="https://umyotube.com" target="_blank"
              >UMYO Tube</a
            >
            <a class="btn-link" href="https://umyosms.com" target="_blank"
              >UMYO SMS</a
            >
            <a class="btn-link" href="https://vibetalktv.com" target="_blank"
              >UMYO PPV</a
            >
            <a class="btn-link" href="https://umyoeducator.com" target="_blank"
              >UMYO LMS</a
            >
            <a
              class="btn-link"
              href="https://lieutenantsacademydfw.org/"
              target="_blank"
              >UMYO VPN</a
            >
            <a
              class="btn-link"
              href="https://umyobiz.com/membership-packages/"
              target="_blank"
              >UMYO Store</a
            >
            <a
              class="btn-link"
              href="https://umyobiz.com/network/"
              target="_blank"
              >UMYO Profile</a
            >
            <a
              class="btn-link"
              href="https://lieutenantsacademydfw.org/"
              target="_blank"
              >UMYO Ambassadors</a
            >
            <a class="btn-link" href="https://umyoauthor.site" target="_blank"
              >UMYO Authors</a
            >
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="footer-item">
          <h4 class="text-light mb-3">LET'S CONNECT</h4>
          <p class="mb-4">
            Subscribe to stay up to date on all the latest UMYO features,
            products, stories, and more. You can also contact us at
            contact@umyo.com.
          </p>
          <div class="position-relative mx-auto">
            <input
              class="form-control border-0 py-2 w-100"
              type="text"
              placeholder="Enter Your Email"
            />
            <button
              type="submit"
              class="btn btn-info border-0 border-secondary position-absolute py-2 text-white"
              style="top: 0; right: 0"
            >
              Sent
            </button>
          </div>
        </div>
        <div class="d-flex pt-3">
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-twitter"></i
          ></a>
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-facebook-f"></i
          ></a>
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-instagram"></i
          ></a>
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-youtube"></i
          ></a>
          <a
            class="btn btn-outline-secondary btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-linkedin-in"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="col-md-12 text-center text-md-start my-5 mb-md-0">
      <p class="text-white text-center">
        <a href="#" style="text-decoration: none; color: white"
          ><i class="fas fa-copyright text-light me-2"></i> 2023 UMYO</a
        >, All right reserved.
      </p>
    </div>
  </div>
</div>
<!-- Footer Start -->

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ show: showPolicyModal, 'd-block': showPolicyModal }"
  style="background-color: rgb(0, 0, 0, 0.8)"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="privacyModalLabel">Privacy Policy</h5>
        <button type="button" class="close" (click)="closePolicyModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <pre
            style="
              max-height: 70vh;
              white-space: pre-wrap;
              overflow-x: hidden;
              overflow-y: scroll;
            "
          >
              Privacy Policy
  
  Last modified: January 10, 2022
  
  Who We Are
  
  Welcome! We are  LgbtqInc., a Texas corporation, or as we prefer to be known, " Academy ,"
  "we," "our," or "us." Our mission is to revolutionize how the world connects! We provide
  products,services, and technologies ("Products") allowing users ("Users") to organize,
  display, and share information with their contacts through our software, mobile
  application, and website (collectively,"Platform").
  
  We are committed to safeguarding our User’s privacy in accordance with this Privacy Policy
  ("Policy").Please carefully read this Policy to comprehend our policies and practices
  concerning your information and how we handle it. If you disagree with our policies and
  practices, please refrain from using our Platform or purchasing our Products.
  
  How to Contact Us
  
  If you have any questions about our practices or this Policy, please reach out to us via
  email at data-privacy@ Academy .com or by mail at  LgbtqInc., Attn: Data Privacy
  Officer, 1709 Martin Luther King Blvd Dallas, Texas, 75215
  
  Application of this Policy
  
  Upon accessing our Platform or utilizing our Products, you become a User and agree to
  this Policy, our Data Processing Addendum, and our Terms of Service and Sale
  (collectively, "Policies").
  
  This Policy delineates the types of information we may collect from you or that you may
  provide to us when interacting with our Platform or Products. It further outlines
  our practices for collecting,
  using, maintaining, protecting, and disclosing that information.
  
  Categories of Information We Collect or Receive
  
  Our Platform and Products empower Users to customize their pages and profiles by
  uploading and linking content and information, shareable with other Users or third
  parties. The information provided by a User on the Platform can be extensive. We
  may receive information directly from you or automatically as you access, use, or
  interact with our Platform or Products.
  
  The categories of information we collect or receive include:
  
  - **Identity or Contact Information:** Your first name, last name, or similar
   identifier title, postal addresses, unique personal identifiers, online
  identifiers, IP addresses, email addresses, social media accounts or identifiers
  (e.g., username), age, date of birth, or other similar contact information
  or forms of identification.
  
  - **Transaction Information:** Details about payments to and from you, as well
   as other details of subscriptions and services you have purchased from us.
  
  - **Content Data Information:** Any content you post on the Platform, such as
    pictures, third-party links, documents, writings, preference settings, messages,
    comments, and other contributions, along with metadata about them.
  
  - **Marketing and Communications Information:** Your preferences for receiving
    marketing communications from us and your communication preferences.
    Correspondence content, if any, may be retained.
  
  - **Behavioral and Usage Information:** Details of your access, use, or 
    interaction with our Platform or Products, including traffic data, location
    data, logs, and other communication data.
  
  - **Technical Information:** IP address, browser type and version, time zone
    setting and location, browser plug-in types and versions, operating system
    and platform, and other technology on the devices you use to access our Platform.
  
  - **Aggregated Data or Information:** We may collect, use, and share aggregated 
    data or information such as statistical or demographic data derived from your 
    Personal Information.
  
  Because Users can upload various information on our Platform, we may also receive
  sensitive information, such as your date of birth, racial or ethnic origin, sex,
  political views, affiliations, veteran or military status, religious or philosophical
  views, or similar information. We do not mandate the provision of Sensitive Information,
  but such information may be indirectly collected by us through our hosting of the Platform.
  
  Our Platform also allows you to upload and share information that is not Personal Information
  or Sensitive
  Information, such as your hobbies, pictures, links, descriptions, messages, and comments
  ("Other Information"). As our Platform permits you to choose the Information associated
  with your account, we may link Other Information to Personal or Sensitive Information 
  aboutyou.
  
  For more information on our use of cookies, logs, web beacons, and other tracking
  technologies,refer to the "Third-Party Sources, Cookies, and Other Tracking
  Technologies" section below.
  
  Third-Party Sources, Cookies, and Other Tracking Technologies
  
  We may also collect or receive Personal Information from third-party sources, and the Platform
  may include links to other sites. Any Information you provide on linked pages is submitted
  directly to the respective third-party and is subject to their terms and policies. This 
  Policy does not extend to such linked third-party sites, and we disclaim responsibility 
  for the content, privacy, security practices, and policies of these external sites or any
  other sites linked to or from our Platform.
  
  Certain Information on the Platform may be served by third parties, including advertisers,
  ad networks and servers, content providers, and application providers. These third parties
  may utilize cookies independently or in conjunction with web beacons or other tracking
  technologies to collect Information about you. The Information they gather may be linked
  to your Personal Information or Sensitive Information, or they may collect Information,
  including Personal Information, about your activities across different websites and online
  services.This Information may be used to provide you with interest-based advertising or other
  targeted content. We do not oversee third-party tracking technologies or their usage. For 
  inquiries regarding advertisements or other targeted content, please directly contact the 
  responsible provider.
  
  We may collect Information regarding your Platform usage, encompassing the pages and links
  viewed, time spent on our Platform, demographic data such as server locations, connection
  speed, and other non-identifying Information. Like most online services, we employ cookies
  , log files, clear GIFs, tracking pixels, web beacons, and other technologies that may collect
  Personal Information.
  
  Upon visiting our Platform, one or more "cookies" – small data files – may be sent to your
  device to uniquely identify your browser, enabling us to enhance your navigation experience.
  Cookies may convey Information about your Platform or Products usage to provide you with a 
  more personalized experience. Persistent cookies remain on your device after closing your 
  browser, allowing your browser to use them in subsequent visits. These can be removed by 
  following your web browser's instructions. Session cookies are temporary and vanish after 
  closing your browser.Our Platform may utilize the following types of cookies, logs, web
  beacons, and other tracking 
   technology:
  
  - **Essential Cookies:** Necessary to provide you with services available on our Platform, 
  facilitating actions like logging in to secure areas and expediting Content loading.
  
  - **Functionality Cookies:** Allow us to remember your choices, such as login information, 
    providing a more personalized experience.
  
  - **Analytics and Performance Cookies:** Collect Information about traffic to our Platform 
    and Users' interactions to enhance Platform efficiency and gather aggregated, anonymous
    demographic data.
    Third-party tools and services may be utilized for this purpose.
  
  - **Targeted and Advertising Cookies:** Track browsing habits to show relevant content.
   Information about browsing history may group Users with similar interests, enabling
    third-parties to display ads matching those interests.
  
  - **Social Media Cookies:** Activate when sharing or engaging with Content on social
    media platforms,helping track conversions, optimize ads, and build targeted audiences.
  
  - **Log Files:** Automatically reported by your browser, recording information such
   as web requests, IP addresses, browser types, and pages viewed. Servers automatically
  capture this data whenever you access our Platform.
  
  ### How Can I Disable Cookies?
  
  You can typically configure your web browser to block all cookies or prompt you when
  a cookie is being sent.To do this, follow the instructions provided by your browser,
  usually located within the “settings,” “help,” or “tools” function. Many browsers
  are set to accept cookies by default until you modify your settings.
  
  Should you choose not to accept our cookies, it may result in some inconvenience
  during your use of our Platform, and certain features may not operate correctly.
  For instance, we may be unable to recognize your device, necessitating you to log
  in each time you visit our Platform.
  
  For more details on cookies, including viewing and managing cookies on your device, 
  visit www.allaboutcookies.org or www.whatarecookies.com.
  
  As of now, we do not acknowledge web browsers’ “do not track” signals that offer an
  opt-out method from collecting Information about User activities on the Platform.
  If we adopt this practice in the future, relevant information will be provided in
  this Policy.
  
  ### How We Use Your Information
  
  We utilize the Information collected about you or provided by you, including any
  Personal Information:
  
  - To present our Platform and enhance its contents, providing a personalized
    and improved experience for Users.
  - To furnish information, products, or services requested by you, responding
    to inquiries or facilitating purchases 
    and deliveries.
  - To enable your participation in interactive features, allowing the sharing
    of Personal Information with other Users.
  - To send notices regarding your accounts, pages, or subscriptions, including
    expiry and renewal notifications.
  - To fulfill our contractual obligations and enforce rights, including
    billing and collection.
  - To inform you of changes to our Platform, Products, or services.
  - To uphold the safety, security, and integrity of the Platform, Products,
    databases, and technological assets.
  - For testing, research, analysis, and product development, enhancing and
     evolving our Platform and Products.
  - To respond to law enforcement requests and comply with applicable law,
    court orders, or governmental regulations.
  - In any other manner as described when providing the Information or as
     outlined in applicable law, including the CCPA and GDPR.
  - For any other purpose with your consent.
  
  ### Disclosure of Your Information
  
  We may share your Information, including Personal Information, by disclosing
  it to third parties for the provision of our Platform, its Content, and security,
  or for marketing and selling our Platform or Products. 
  These disclosures occur under written contracts specifying purposes,
  confidentiality requirements, and prohibiting use for any purpose other than contract
   performance. We do not sell your Personal Information.
  
  For Users with an account linked to a “ Lgbtqfor Teams” subscription, the administrator 
  associated with your “ Lgbtqfor Teams” account may manage, access, view, or store your
  Information, including Personal Information. We do not control or limit your account's
  accessibility, and we are not responsible for the administrator's access, disclosure, or
  processing of your Information.
  
  Within the last twelve (12) months, we have disclosed Information, as described in this Policy:
  
  - In connection with your use of our Platform or Products, enabling the sharing of Personal
    Information with other Users.
  - To our service providers, affiliates, agents, subprocessors, advertising partners, 
    internet service providers, data analytics providers, and related third parties, supporting
    Platform and Product functions.
  - To our processors and subprocessors, engaging specific service providers to assist in
     Platform and Product support.
  
  **List of Subprocessors:**
  
  - **Google LLC:** Cloud Service Provider (United States)
  - **Stripe:** Payment Processor (United States)
  - **Hubspot:** Marketing, Sales (CRM), and Support Software Platform (United States)
  - **Chili Piper:** Scheduling Software (United States)
  - **Zapier:** Workflow Integration Software (United States)
  - **Slack:** Messaging and Communication Platform (United States)
  - **Amplitude:** Product and Data Analytics (United States)
  - **Shopify:** Ecommerce Platform (Canada and jurisdictions per Shopify’s policies)
  - **Cloudflare Inc.:** Web Traffic Optimization and Distribution Service (United States)
  - **Covve Visual Network Limited:** Physical business card scanner API (Cyprus)
  - **Rollbar:** Error Monitoring (United States)
  - **Heroku:** Cloud Hosting Provider (United States)
  - **AWS:** Cloud Hosting Provider (United States)
  - **Fivetran:** ETL Tooling (United States)
  - **Looker:** BI (Business Intelligence) tooling (United States)
  - **Cloudinary:** Image hosting API (United States)
  - **Newrelic:** APM (Application Performance Monitoring) (United States)
  - **Passsource:** API (United States)
  - **Klaviyo:** Marketing automation (United States)
  
  **User Data Privacy Policy**
  
  **Effective Date: [Date]**
  
  Welcome to  Academy ! This User Data Privacy Policy ("Policy") outlines how we collect,
  use, disclose, and protect your information when you use our platform, products, and
  services ("Platform" or "Products"). Please read this Policy carefully to understand
  our practices regarding your information and how we will treat it.
  
  ### 1. **How Can I Disable Cookies?**
  
  You have the ability to configure your web browser to block cookies or prompt
  you when a cookie is being sent. Refer to your browser's settings, usually
  located in the "settings," "help," or "tools" function. Please note that
  if you choose to disable our cookies, it may impact your experience on the Platform,
  and certain features may not operate correctly. For more information on cookies,
  including how tomanage them, visit [www.allaboutcookies.org](www.allaboutcookies.org)
  or [www.whatarecookies.com](www.whatarecookies.com).
  
  As of now, we do not acknowledge "do not track" signals from web browsers. If this
  changes in the future, we will provide relevant information in this Policy.
  
  ### 2. **How We Use Your Information**
  
  We use the information collected or provided by you for various purposes, including
  but not limited to:
  
  - Presenting and enhancing the Platform.
  - Providing information, products, or services as requested.
  - Enabling participation in interactive features and sharing information with other Users.
  - Sending notices regarding accounts, pages, or subscriptions.
  - Fulfilling contractual obligations, including billing and collection.
  - Informing you of changes to our Platform, Products, or services.
  - Upholding the safety, security, and integrity of our Platform and Products.
  - Conducting testing, research, analysis, and product development.
  - Responding to law enforcement requests and complying with applicable laws.
  
  ### 3. **Disclosure of Your Information**
  
  We may share your information, including Personal Information, with third parties for various 
  purposes,such as providing our Platform, securing it, and marketing or selling our Products.
  These disclosures are governed by written contracts specifying purposes and confidentiality
  requirements. We do not sell your Personal Information.
  
  **List of Subprocessors:**
  - Google LLC (Cloud Service Provider, United States)
  - Stripe (Payment Processor, United States)
  - Hubspot (Marketing, Sales, and Support Software Platform, United States)
  - Chili Piper (Scheduling Software, United States)
  - Zapier (Workflow Integration Software, United States)
  - Slack (Messaging and Communication Platform, United States)
  - Amplitude (Product and Data Analytics, United States)
  - Shopify (Ecommerce Platform, Canada and jurisdictions per Shopify’s policies)
  - Cloudflare Inc. (Web Traffic Optimization and Distribution Service, United States)
  - Covve Visual Network Limited (Physical business card scanner API, Cyprus)
  - Rollbar (Error Monitoring, United States)
  - Heroku (Cloud Hosting Provider, United States)
  - AWS (Cloud Hosting Provider, United States)
  - Fivetran (ETL Tooling, United States)
  - Looker (BI tooling, United States)
  - Cloudinary (Image hosting API, United States)
  - Newrelic (APM, United States)
  - Passsource (API, United States)
  - Klaviyo (Marketing automation, United States)
  
  ### 4. **Where We Store Your Information**
  
  We maintain our Platform in the United States, and your information may be stored,
  processed, and accessed in the U.S. or elsewhere by us, our employees, contractors,
  and third parties. By using our Platform and providing your information, you consent
  to the export of Personal Information to the United States, understanding that U.S.
  laws may differ from those in your country.
  
  ### 5. **How Long We Store Your Information**
  
  We retain your information for as long as reasonably necessary for the purposes
  outlined in this Policy, unless a longer retention period is required by law. We
  may keep your information for business interests, audits, legal compliance, dispute
  resolution, and agreement enforcement.
  
  ### 6. **Your Rights Related to Your Personal Information**
  
  You may have rights under laws such as the CCPA and GDPR. These rights may include:
  
  - Requesting access to your Personal Information.
  - Right to Know (CCPA) – Receiving information about the collection and use of your
     Personal Information.
  - Requesting correction of your Personal Information.
  - Requesting erasure of your Personal Information.
  - Objecting to processing or requesting restriction of processing.
  - Requesting the transfer of your Personal Information.
  - Withdrawing consent.
  
  ### 7. **How to Exercise Your Rights**
  
  To exercise your rights or inquire about your Personal Information, contact us at 
  [data-privacy@ Academy .com] (mailto:data-privacy@ Academy .com) or by mail at
   LgbtqInc., Attn: Data Privacy Officer, [Address].
  
  We will confirm receipt of your request within ten (10) business days and aim to
  respond within one month, unless the request is complex. If applicable, any disclosures
  will cover the 12-month period preceding the request.
  
  ### 8. **Non-Discrimination**
  
  We do not discriminate against you for exercising your rights. We will not deny goods or
  services, charge different prices, or provide different quality levels based on your
  exercise of privacy rights, unless permitted by law.
  
  ### 9. **How We Protect Your Information**
  
  We implement measures to secure your information from loss and unauthorized access.
  All information is stored on secure servers, and payment transactions are encrypted
  using SSL technology.
  
  ### 10. **Children Under the Age of 16**
  
  Our Platform is not intended for children under 16. We do not knowingly collect
  Personal Information from children under 16. If you are under 16, please do not
  use the Platform or provide any information. If we learn of such information, we
  will delete it promptly.
  
  ### 11. **Opting Out of Sale of Personal Information**
  
  We do not sell Personal Information. If you are 16 or older, you can direct us not
  to sell your information at any time by contacting us at 
  [data-privacy@ Academy .com](mailto:data-privacy@ Academy .com) or by mail at
   LgbtqInc., Attn: Data Privacy Officer, 1709 MLK BLVD Dallas, Texas 75215
  
  **Changes to Our Privacy Policy at  Academy **
  
  You acknowledge that this Policy is subject to modification by us at any time,
  without prior written notice,and solely at our discretion. Any revisions to this
  Policy will take effect on the "Last Updated Date" or "Last Revised Date"
  indicated on the Platform. Therefore, it is advisable to review our Policies before
  making any purchases or using our Products, Platform, or associated services. Your
  ongoing use of the Platform or any Product following the "Last Updated Date" or "Last
  Revised Date" will signify your acceptance and agreement to these modifications
  
            </pre
          >
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ show: showRefundModal, 'd-block': showRefundModal }"
  style="background-color: rgb(0, 0, 0, 0.8)"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="refundModalLabel">Refund Policy</h5>
        <button type="button" class="close" (click)="closeRefundModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <pre
            style="
              max-height: 70vh;
              white-space: pre-wrap;
              overflow-y: scroll;
              overflow-x: hidden;
            "
          >
              **Refund Policy at  Academy **
  
  **Returns for Defective Items:**
  Our steadfast commitment to delivering high-quality custom items remains unwavering. In the
  event that you receive a defective or damaged item, kindly notify us within 30 days of
  receipt. We will promptly offer a replacement or a full refund for the returned items.
  
  **Return for Customization Issues:**
  If the item received does not align with the agreed-upon customization specifications,
  please inform us within 30 days of receipt. We will collaborate with you to replace
  and reprint it correctly to your satisfaction. In some cases, we may request the return
  of the item to our office to address any issues that occurred during the printing process.
  
  **No Returns for Change of Mind:**
  Due to the personalized nature of our custom items, returns or exchanges stemming from a
  change of mind are not feasible. We encourage you to thoroughly review all customization
  details before confirming your order. This enables us to promptly commence crafting your
  item, ensuring a smooth and efficient process.
  
  **Communication on Specifications:**
  To expedite the process and ensure your custom item exceeds expectations, we rely on
  comprehensive and precise specifications during the ordering process. Your timely
  and detailed communication allows us to swiftly proceed with crafting your custom
  piece, reflecting our commitment to your satisfaction.
  
  If you believe your order requires additional details, please notify us promptly.
  As the fastest custom NFC option on the market, early communication enhances our
  ability to address any potential issues.
  
  **Cancellation Policy:**
  You can cancel your order within 1-2 business days of purchase, provided we haven't
  initiated the creation of your custom item. If cancellation is requested after this
  timeframe, a 10% cancellation fee will be applied to cover the cost of materials and
  work invested in your unique item. This fee will be deducted from your refund.
  
            </pre
          >
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ show: showTermsModal, 'd-block': showTermsModal }"
  style="background-color: rgb(0, 0, 0, 0.8)"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="termsModalLabel">Terms Of Service</h5>
        <button
          type="button"
          class="close"
          (click)="closeTermsModal()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <pre
            style="
              max-height: 70vh;
              white-space: pre-wrap;
              overflow-y: scroll;
              overflow-x: hidden;
            "
          >
              Welcome to  Academy !
  
  **Terms of Service and Sale -  Academy **
  
  **Last Revised: December 22, 2023**
  
  **Terms of Sale and Service**
  
  **Introduction**
  
  Welcome! We are  Academy , a Delaware corporation, known as "we," "our," or "us." Our mission
  is to redefine how the world connects. We provide Products, Services, and Technologies
  ("Products") that enable Users ("Users") to organize, display, and share information
  with others and third parties ("Services") through our software, mobile application, and
  website (collectively, "Platform").
  
  By submitting an order through the Platform or otherwise ("Order"), you (whether on behalf
  of yourself or an entity you represent) are entering into these Terms of Sale and Service
  ("Terms") with us. You agree to be bound by these Terms and other conditions governing the
  use of our Services, Platform, or the Products offered or sold, such as our Privacy Policy
  and Data Processing Addendum (collectively, "Policies"). If you are under 16, you may not
  use our Platform or Services without parental or guardian permission.
  
  If you disagree with these Terms or our Policies, do not submit an Order or use our
  Platform, Services, or Products.
  
  By agreeing, you confirm that you have the authority to bind yourself and/or the entity
  or company you represent. We may periodically modify these Terms or any Policies at our
  discretion, with the "Last Revised" date indicating such changes.
  
  **Purchase of Products**
  
  (a) When you place an Order, you make an offer to purchase Products according to the
  Order terms, these Terms, and our Policies. An Order is effective upon our acceptance
  or rejection. We may accept or reject Orders at our discretion. Product prices (the
  "Product Purchase Price") are as stated in the Order or, if not in an Order, as
  advertised on our Platform when we accept the Order. Prices exclude taxes; you are
  responsible for such charges.
  
  (b) We select shipment methods and carriers unless specified in the Order. Partial
  shipments are possible at our discretion. You pay for shipped units, including shipping
  and insurance costs unless otherwise stated in the Order.
  
  (c) Products will be delivered to the specified address ("Delivery Point") within a
  reasonable time. Custom Products may have longer processing times. Delivery times are
  estimates, and we are not liable for delays. Title and risk pass upon delivery. If you
  fail to accept delivery, risk passes to you, and we may store the Products at your cost.
  
  (d) Inspect Products within ten (10) days of delivery ("Inspection Period"). Accept or reject
  Nonconforming Products (materially different or defective) during the Inspection Period. If
  notified of Nonconforming Products, ship them to us, and we may replace them.
  
  (e) We warrant Products against significant defects for 1 year ("Warranty Period"). The
  warranty doesn't apply if Products are misused, altered, or used with unauthorized hardware.
  Except for this warranty, we provide no other warranties.
  
  (f) We are not liable for a breach of the warranty unless you give written notice within
  10 days of discovering the defect, allow us to examine the Products, and we verify the
  Nonconforming Products. Remedies for Nonconforming Products are repair, replacement,
  credit, or refund at our discretion.
  
  (g) Your sole remedy for Nonconforming Products is as outlined in Section 2(f).
  
  (h) Changes to Terms in Orders are void unless expressly stated.
  
  **Services**
  
  (a) To use our Services, register and create an account ("Account"). Our Services allow
  Users to create, design, maintain, and share profiles or pages ("Pages"). Subscription
  Levels offer different access levels.
  
  (b) During the Subscription Term, you are granted licensed seats for Services based on
  an accepted Order. Users are responsible for complying with these Terms and our Policies.
  Some Products may require an Account Administrator.
  
  (c) We may establish policies and practices for the Platform, including deleting or
  removing Content, terminating inactive Accounts, or changing Services. Our Services
  may be changed, suspended, or discontinued at any time.
  
  (d) You are responsible for your Account's security. Notify us of any unauthorized
  use promptly. We are not liable for third-party access resulting from your failure
  to comply with these Terms.
  
  **Subscription; Payment Terms**
  
  (a) We offer various Subscription Levels with associated Subscription Fees. Fees
  are subject to change, impacting renewals but not current Subscription Terms.
  
  (b) You agree to pay Subscription Fees associated with your Subscription. Payment
   terms are as stated on our Platform or Order.
  
  (c) We may collect payments through third-party processors. By providing payment
  information, you authorize us to charge your payment method for Fees. Failure to
  verify or accept your payment method may result in suspension or cancellation.
  
  (d) Subscription Fees may consist of recurring charges. By choosing a recurring
  payment plan, you acknowledge and accept responsibility for all recurring charges.
  
  (e) We may submit periodic charges without further authorization until notice of
  termination or payment method change is provided. Charges submitted before notice will
  not be affected.
  
  (f) Your subscription will automatically renew unless terminated before the end of
  the then-current Subscription Term. Termination may occur through Account settings.
  No refunds are provided for early termination.
  
  Certainly! Here's the rewritten version:
  
  ---
  
  ** LgbtqApp Terms of Service**
  
  **1. Account Information and Updates**
  
  (g) You must provide accurate, current, and complete information for your  Academy 
  account. Ensure timely updates for your billing account, including changes to
  billing address, credit card number, or expiration date. Promptly notify us or
  our payment processor about canceled payment methods or potential security breaches.
  Make changes through your account settings. Failure to provide accurate information
  authorizes us to continue charging subscription fees.
  
  **2. Free Trials and Subscriptions**
  
  (h) Free trials for paid subscription levels are offered with specific terms disclosed
   during the offer. Trials are for users without prior paid subscriptions. Obtaining a
  free tier allows one account creation. Non-genuine use may result in standard fees or
   service denial. Acceptance of free trial subscription implies agreement to associated
    fees upon expiration unless canceled.
  
  **3. User-Generated Content**
  
  (a) Users can post content ("Content") on  Academy . Posted Content is not confidential,
   treated as public information. By posting, users represent having the right to do so
  and grant us a non-exclusive license to use it for platform operation and promotion.
  
  (b) Users warrant that Content doesn't infringe third-party rights. We reserve the
   right to review and may refuse or remove Content violating terms, policies, or
  laws. Prohibited actions include unlawful, harmful, or objectionable Content;
  collecting personal information without consent; creating derivative works; and
  interfering with platform operations.
  
  (c) The platform may contain links to third-party services ("Third Party
  Services"). These services are not covered by our terms, and we are not
  responsible for them.
  
  (d) Platform content is protected by intellectual property laws. All related
  rights are exclusively ours. Users providing feedback grant us rights to use it freely.
  
  **4. Limitation on Liability**
  
  (a) We're not liable for user-posted Content, user interactions, losses from platform
  use, negative comments, or third-party information provided without consent. Users are
  responsible for interactions with others; we may not intervene in disputes.
  
  (b) We're not liable for indirect, incidental, or exemplary damages arising from a
   breach of terms, regardless of foreseeability. Our aggregate liability is limited
  to the total amounts paid by you in the previous 12 months.
  
  **5. Indemnification**
  
  Users indemnify us against any losses arising from claims by third parties related to
  platform use, purchased products or services, or user negligence or breach of terms.
  
  **6. Governing Law**
  
  These terms are governed by the laws of the State of Alabama.
  
  **7. Binding Arbitration**
  
  (a) By accepting these terms, you waive the right to a jury trial and class action
  participation. Disputes are resolved through binding arbitration. Some claims are
  exempted, including those regarding intellectual property rights or small claims.
  
  (b) Disputes must be addressed informally for at least 30 days before arbitration.
  If unresolved, either party may elect binding arbitration administered by the
  American Arbitration Association in Birmingham, Alabama.
  
  **8. General**
  
  (a) Unenforceability of one provision won't affect others. In case of conflict,
  we decide which provision prevails. Headings are for convenience. Our
  non-enforcement doesn't waive future enforcement rights.
  
  (b) Products and services may be subject to US export control laws. Users
   are responsible for compliance. Communication with us is electronic,
   fulfilling legal requirements.
  
  (c) Notices are provided within the platform or through services. Users
    can notify us via mail.
  
  (d) "Include" or "including" is not limiting, and "or" means "and/or."
  
  ---
  
            </pre
          >
        </div>
      </div>
    </div>
  </div>
</div>
